.task {
  /* margin: 10px 0px; */
  /* border-bottom: 1px solid rgb(194, 194, 194); */
  /* border-radius: 10px; */
  /* padding: 10px; */

  --space-between-tasks: 8px;
  position: relative;
  padding-top: var(--space-between-tasks);
}

.main {
  display: flex;
  margin-right: 10px;
}

.checkmark-container {
  margin: 5px 10px;
}

.checkmark {
  color: gray;
  cursor: pointer;
}

.content-container {
  display: flex;
  padding-bottom: var(--space-between-tasks);
  border-bottom: 1px solid rgb(207, 207, 207);
  width: 100%;
}

.no-border {
  border: none;
}

.content {
  border: none;
  background-color: transparent;
  width: 100%;
  resize: none;
  outline: none;
  font-family: inherit;
  font-size: inherit;

  line-height: 1.5;
  font-size: 14px;

  /* still grows but avoids the bump when start typing due to line height stuff of cursor vs text */
  height: 24px;
}

.content::placeholder {
  font-size: 16px;
}

.content-edited::first-line {
  color: black;
  /* font-weight: 600; */
  font-size: 16px;
}

.task-buttons {
  display: flex;
  opacity: 0;
  transition: opacity 0.2s;
}

.task:hover .task-buttons {
  opacity: 1;
}

.column-buttons {
  display: flex;
  flex-direction: column;
  height: 32px;
  width: 18px;
}

.column-buttons.button {
  width: 18px;
  height: 16px;
}

.column-buttons.up-down {
  margin-right: 2px;
  width: 24px;
}

.column-buttons .up-down {
  width: 24px;
}

.edit-buttons {
  color: rgb(70, 70, 70);
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  margin: 0px;
  background-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-buttons:hover {
  background-color: rgb(245, 245, 245);
}

.edit-buttons.delete {
  color: rgb(194, 0, 0);
}

.edit-buttons.settings {
  font-size: 12px;
  flex-basis: 16px;
}

.reminders {
  font-size: 12px;
  background-color: transparent;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
  /* border: 1px solid gray; */
  border-radius: 12px;
  display: inline-block;
  padding: 3px 6px;
}

footer {
  display: flex;
  height: 18px;
}

.footer-buttons {
  margin-left: auto;
}
