.dropdown {
  position: absolute;
  top: 16px;
  right: 16px;
  /* bottom: 0px;
  left: 0px; */
  /* width: 156px; */
  background-color: white;
  box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
}

.dropdown .menu-item {
  /* display: flex; */
  width: 100%;
  font-size: 14px;
  color: rgb(59, 59, 59);
  padding: 8px 10px;
  border-bottom: 1px solid rgb(233, 233, 233);
  cursor: pointer;
}

.dropdown .menu-item:hover {
  background-color: rgb(248, 248, 248);
}

.menu-item:last-child {
  border-bottom: none;
}

.dropdown .icon {
  display: inline-block;
  width: 18px;
  height: 16px;
  font-size: 14px;
}
