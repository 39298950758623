.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.5);
}

.modal-content {
  margin: 0px 10px;
  width: 500px;
  border-radius: 2px;
  box-shadow: 0px 12px 32px -4px rgba(0, 0, 0, 0.3);
  padding: 24px;
  background: white;
  position: relative;
}

.modal-content h1 {
  margin-top: 0px;
}

.sign-in-with {
  display: grid;
  place-items: center;
}

.google-sign-in {
  display: flex;
  justify-content: center;
  align-items: center;
}

.google-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.or-divider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 16px;
}

.or-divider-line {
  border-top: 1px solid rgb(228, 228, 228);
  width: 100%;
}

.or-divider-text {
  text-align: center;
  margin: 0px 10px;
}

.modal-container label {
  display: block;
  margin-top: 12px;
  margin-bottom: 8px;
}

.modal-container input {
  display: block;
  width: 100%;
  padding: 8px 12px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #aaa;
}

.modal-container .submit-button {
  float: right;
  margin: 10px 0px;
  width: auto;
}

form .user-button {
  cursor: pointer;
  border: none;
  margin: 10px 0px;
  float: right;
  width: auto;
}

.footer {
  clear: both;
}

.footer .error {
  background-color: rgb(255, 217, 217);
  padding: 6px;
  margin-bottom: 10px;
}

.footer p {
  margin: 0;
}

.modal-container .footer button {
  padding: 0px;
  background-color: transparent;
  color: var(--blue-color);
  cursor: pointer;
}

.modal-container .footer button:hover {
  text-decoration: underline;
}

.exit-button {
  color: rgb(165, 165, 165);
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}

.exit-button:hover {
  color: gray;
}

.new-user-content .welcome {
  color: rgb(21, 150, 255);
}

.new-user-content .subject-line {
  font-style: italic;
}

.new-user-content button {
  float: right;
}
