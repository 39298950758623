:root {
  --base-color: rgb(61, 61, 61);
  --blue-color: rgb(0, 153, 255);
}

.App {
  /* margin: 0 auto;
  width: calc(30% + 500px); */
  padding-bottom: 20px;
}

/* || General UI */

p,
textarea {
  color: var(--base-color);
}

h1,
h2,
h3 {
  color: black;
}

a {
  color: var(--blue-color);
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button {
  border: none;
  cursor: pointer;
}

.bold {
  font-weight: 500;
}

.background-button {
  border: none;
  padding: 6px 10px;
  border-radius: 3px;
  cursor: pointer;
  transition: transform 0.1s, opacity 0.1s, background-color 0.1s;

  background-color: rgb(240, 240, 240);
}

.background-button:hover {
  background-color: rgb(245, 245, 245);
}

.clear-button {
  border: none;
  padding: 4px 8px;
  border-radius: 25%;
  cursor: pointer;

  background-color: white;
}

.clear-button:hover {
  background-color: rgb(240, 240, 240);
}

.background-button:active {
  outline: none;
}

/* || HEADER */

header {
  display: flex;
}

header .right {
  margin-left: auto;
  padding: 8px;
  text-align: right;
}

.right button {
  margin-right: 8px;
}

.user-button {
  background-color: rgb(65, 170, 255);
  color: white;
  border: none;
  padding: 6px 10px;
  border-radius: 3px;
  /* text-transform: uppercase; */
  font-weight: 600;
  font-size: 15px;
  border: 1px solid white;

  height: 32px;
  box-sizing: border-box;
  display: inline-block;
}

.user-button:hover {
  background-color: rgb(0, 140, 255);
}

.logged-in {
  background-color: white;
  border: 1px solid rgb(170, 217, 255);
  /* box-shadow: 0px 0px 0px 2px rgb(65, 170, 255); */
  color: rgb(0, 140, 255);
}

.logged-in br {
  display: none;
}

.logged-in:hover {
  background-color: white;
}

.edit-user-name {
  resize: none;
}

.edit-user-name:hover {
  background-color: white;
}

.sign-out-button {
  color: rgb(59, 59, 59);
  vertical-align: top;
  border: 1px solid rgb(235, 235, 235);
  padding: 6px 10px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  background-color: white;
  font-size: 14px;
  transition: background-color 0.1s;
}

.sign-out-button:hover {
  background-color: rgb(245, 245, 245);
}

/* || Main content page */

main {
  margin: 0 auto;
  width: calc(25% + 600px);
}

@media print, screen and (max-width: 850px) {
  main {
    box-sizing: border-box;
    width: 100%;
    padding: 0px 20px;
  }
}

.page-container {
  display: flex;
}

.persistent-tasks-title {
  margin-top: 4px;
}

.daily-container {
  flex: 2;
  margin-right: 10px;
}

@media print, screen and (max-width: 650px) {
  .daily-container {
    margin-right: 0px;
  }
  .page-container {
    display: block;
  }
  .persistent-tasks-title {
    margin-bottom: 15px;
  }
}

.day-nav-date {
  font-size: 14px;
  margin-right: 5px;
}

.persistent-container {
  flex: 1;
}

.title {
  margin: 0px 0px 15px 0px;
  text-transform: capitalize;
  flex: 1;
}

.date-subtitle {
  margin-bottom: 20px;
  font-size: 14px;
}

.main-header {
  display: flex;
}

.day-nav {
  flex: 1;
  margin: -10px 20px 0px auto;
  padding-left: 15px;
  display: flex;
  align-items: center;
  text-align: right;
}

.day-nav button {
  padding: 6px 10px;
  border-radius: 20px;
  margin-left: 4px;
  background-color: white;
}

.recurring-title {
  text-transform: uppercase;
  font-size: 12px;
  color: gray;
  margin-top: 26px;
}

.day-nav button:hover {
  background-color: rgb(245, 245, 245);
}

#today-button {
  margin-left: 0px;
  color: rgb(48, 48, 48);
  font-size: 14px;
}
.new-task {
  /* display: grid;
  place-items: center; */
  margin-top: 10px;
}

.warning-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  transition: opacity 3s;
}

.warning-content {
  line-height: 1.5;
  color: gray;
  font-size: 14px;
  border: 1px solid rgb(240, 240, 240);
  /* background-color: rgb(252, 252, 252); */
  background-color: white;
  padding-left: 14px;
  padding-right: 18px;
  border-radius: 3px;
  position: relative;
}

@media print, screen and (max-width: 450px) {
  .warning-container {
    padding-right: 20px;
  }
}

.warning-content a {
  font-weight: 500;
}

.warning-content button {
  color: rgb(0, 132, 255);
  cursor: pointer;
  width: 18px;
  height: 18px;
  /* padding:  */
  font-size: 12px;
  background-color: transparent;
  /* border-radius: 10px;
  border: 1px solid rgb(240, 240, 240); */

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0px;
  right: 0px;
}

.warning-content button:hover {
  color: black;
  background-color: transparent;
}

/* || Tasks */
.new-task-button {
  padding: 6px 10px;
  background-color: white;
  border-radius: 20px;

  color: rgb(32, 32, 32);
  font-weight: 500;

  transition: box-shadow 0.2s, background-color 0.1s;
}
.new-task-plus {
  color: var(--blue-color);
  font-size: 16px;
  margin-right: 8px;
}
.new-task-button:hover {
  opacity: 1;
  background-color: rgb(243, 243, 243);
  /* box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.3); */
}

/* || Completed button */
.completed-button {
  padding: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  box-sizing: border-box;
  background-color: white;

  padding: 0px 10px;
  border-radius: 20px;

  color: rgb(32, 32, 32);
  /* font-weight: 500; */
  font-size: 14px;
  margin-left: 27px;

  transition: box-shadow 0.2s;
}

.completed-button .wrapper {
  margin-top: 4px;
}

.completed-angle {
  display: inline-block;
  color: gray;
  font-size: 20px;
  margin-left: 6px;
  vertical-align: top;
}
.completed-button:hover {
  opacity: 1;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.3);
}
.completed-button:focus {
  outline: none;
}

.persistent-tasks-header button {
  background-color: white;
  border-radius: 50%;
  font-size: 18px;
  color: gray;
  vertical-align: baseline;
}

.persistent-tasks-header button > * {
  vertical-align: bottom;
}

.persistent-tasks-header button:hover {
  background-color: rgb(247, 247, 247);
}

.persistent-tasks-header button:focus {
  outline: none;
}

.download-container {
  display: inline;
}

.header-buttons {
  display: flex;
}

.download-button {
  font-size: 14px;
  color: rgb(49, 49, 49);
  padding: 4px 6px;
  border-radius: 20px;
  margin-left: 4px;
  background-color: rgb(241, 241, 241);
}

.download-button:hover {
  background-color: rgb(223, 223, 223);
}

.download-button.save {
  background-color: rgb(248, 248, 248);
}

.download-button.save:hover {
  background-color: rgb(240, 240, 240);
}

.download-link {
  color: rgb(73, 73, 73);
  display: inline-block;
  margin: 0px 5px;
  vertical-align: bottom;
  /* margin-top: -4px; */
  font-size: 14px;
  margin-bottom: 8px;
}

@media print, screen and (max-width: 450px) {
  main {
    padding-right: 0px;
  }
}

@media print, screen and (max-width: 640px) {
  .download-link {
    display: block;
  }
}
