.notes h2 {
  margin-bottom: 10px;
}

.notes-container {
  display: flex;
}

.notes-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex-basis: 40px;
  width: 40px;
  height: 50px;

  margin-top: 6px;
  margin-bottom: 34px;
  padding: 4px;
  border: 1px solid gray;
  border-radius: 10px;

  font-weight: 600;
  color: rgb(34, 34, 34);
}

.notes-date span:nth-child(1) {
  text-transform: uppercase;
  font-size: 10px;
}

.notes-date span:nth-child(2) {
  font-size: 20px;
}

.notes-content {
  border: none;
  background-color: transparent;
  width: 100%;
  margin-left: 10px;
  resize: none;
  outline: none;
  font-family: inherit;
  font-size: inherit;

  line-height: 1.5;
}

/* .notes-content-edited::first-line {
  font-weight: 600;
  color: black;
  font-size: 18px;
} */
